body {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #fff;
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #333232;
    line-height: 1.6;
    margin-top: 0;
}

#app {
    max-width: 1440px;
    box-sizing: border-box;
    width: 100vw;
    -webkit-transition: top 0.2s;
    -moz-transition: top 0.2s;
    -o-transition: top 0.2s;
    transition: top 0.2s;
}

.content-width {
    width: -webkit-fill-available;
    width: -moz-available;
}

/* Start header styles */
header {
    height: 105px;
    background-color: #004990;
}

#app header.navbar-default {
    width: 100%;
    color: #FFF;
    border: none;
    background-color: transparent;
}

#app header.navbar-default .container,
#app header.navbar-default .container-fluid {
    padding: 0;
    -webkit-transition: background-color 0.4s;
    -moz-transition: background-color 0.4s;
    -o-transition: background-color 0.4s;
    transition: background-color 0.4s;
}

#app header.navbar-default.scrolled .container,
#app header.navbar-default.scrolled .container-fluid {
    background-color: #004990;
}

#app header.navbar-default a,
#app header.navbar-default ul li a {
    color: #FFF;
    -webkit-transition: text-decoration 0.2s;
    -moz-transition: text-decoration 0.2s;
    -o-transition: text-decoration 0.2s;
    transition: text-decoration 0.2s;
}

#app header.navbar-default a:hover,
#app header.navbar-default a:active,
#app header.navbar-default ul li a:hover,
#app header.navbar-default ul li a:active {
    text-decoration: underline;
}

#app header.navbar-default .navbar-header {
    width: 100%;
    position: relative;
}

#app header.navbar-default .navbar-header .logo {
    margin: 0;
    display: inline-block;
}

@media all and (max-width: 768px) {
    #app header.navbar-default .navbar-header .logo {
        width: 180px;
        float: none !important;
    }
}

#app header.navbar-default .navbar-header .logo img {
    z-index: 2;
    position: relative;
    max-width: 183px;
}

@media all and (max-width: 767px) {
    #app header.navbar-default .navbar-header .logo img {
        height: 78px;
    }
}

#app header.navbar-default .navbar-header .name.navbar-brand {
    background: linear-gradient(to right, #211C50, transparent);
    padding-left: 50px;
    left: -30px;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    font-size: 13px;
}

@media all and (max-width: 768px) {
    #app header.navbar-default .navbar-header .name.navbar-brand {
        display: none;
    }
}

#app header.navbar-default .navbar-header #block-personifyssologinblock {
    display: none;
    position: absolute;
    right: 70px;
    top: 14px;
    font-size: 13px;
    text-transform: uppercase;
}

@media all and (min-width: 768px) {
    #app header.navbar-default .navbar-header #block-personifyssologinblock {
        display: inline-block;
    }
}

@media all and (min-width: 768px) {
    #app header.navbar-default .navbar-header #block-ata-search {
        height: auto;
        clear: both;
        width: 100%;
    }
}

#app header.navbar-default .navbar-header #block-ata-search .contextual {
    z-index: 0;
}

#app header.navbar-default .navbar-header #block-ata-search .contextual .trigger {
    right: 25px;
}

#app header.navbar-default .navbar-header .search-block-form {
    position: relative;
}

#app header.navbar-default .navbar-header .search-block-form form#search-block-form #edit-keys--description {
    display: none;
}

#app header.navbar-default .navbar-header .search-block-form form#search-block-form input.form-search {
    border-radius: 0;
    width: 324px;
    box-shadow: none;
    height: 40px;
    font-size: 18px;
}

#app header.navbar-default .navbar-header .search-block-form form#search-block-form .input-group-btn {
    padding: 0;
    display: block;
}

#app header.navbar-default .navbar-header .search-block-form form#search-block-form button.form-submit {
    background-color: transparent;
    border: none;
    border-radius: 0;
    height: 40px;
    width: 80px;
    min-width: auto;
}

#app header.navbar-default .navbar-header .search-block-form form#search-block-form button.form-submit .icon {
    background: none;
}

#app header.navbar-default .navbar-header .search-block-form form#search-block-form.show {
    height: auto;
    opacity: 1;
}

#app header.navbar-default .navbar-header .member-login-wrapper {
    display: inline-block;
    position: absolute;
    right: 80px;
    top: 5px;
}

#app header.navbar-default .navbar-header .member-login-wrapper a {
    text-transform: uppercase;
    font-size: 13px;
}

#app header.navbar-default .navbar-header .region-navigation {
    height: 78px;
}

@media all and (min-width: 768px) {
    #app header.navbar-default .navbar-header .region-navigation {
        height: 50px;
    }
}

#app header.navbar-default .navbar-header .navbar-toggle {
    display: block;
    z-index: 5;
    left: auto;
    right: 0;
    position: absolute;
    border: none;
    margin-top: 10px;
    padding: 12px 10px;
    top: 10px;
}

@media all and (min-width: 768px) {
    #app header.navbar-default .navbar-header .navbar-toggle {
        left: 200px;
        right: auto;
        top: auto;
    }
}

#app header.navbar-default .navbar-header .navbar-toggle:focus,
#app header.navbar-default .navbar-header .navbar-toggle:hover {
    background: none;
}

#app header.navbar-default .navbar-header .navbar-toggle .icon-bar {
    height: 4px;
    width: 30px;
    background-color: #FFF;
    border-radius: 0;
    -webkit-transition: transform 300ms;
    -moz-transition: transform 300ms;
    -ms-transition: transform 300ms;
    -o-transition: transform 300ms;
    transition: transform 300ms;
    position: absolute;
    left: 0px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
}

#app header.navbar-default .navbar-header .navbar-toggle .icon-bar:nth-child(2) {
    top: 4px;
}

#app header.navbar-default .navbar-header .navbar-toggle .icon-bar:nth-child(3) {
    top: 10px;
}

#app header.navbar-default .navbar-header .navbar-toggle .icon-bar:nth-child(4) {
    top: 20px;
    width: 20px;
}

#app header.navbar-default .navbar-header .navbar-toggle[aria-expanded=true] .icon-bar:nth-child(2) {
    top: 14px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

#app header.navbar-default .navbar-header .navbar-toggle[aria-expanded=true] .icon-bar:nth-child(4) {
    opacity: 0;
    left: -70px;
}

#app header.navbar-default .navbar-header .navbar-toggle[aria-expanded=true] .icon-bar:nth-child(3) {
    top: 10px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

#app header.navbar-default .navbar-header #block-quicklinks {
    display: inline-block;
    position: absolute;
    left: 260px;
    top: 57px;
}

@media all and (max-width: 767px) {
    #app header.navbar-default .navbar-header #block-quicklinks {
        display: none !important;
    }
}

#app header.navbar-default .navbar-header #block-quicklinks ul.menu--quick-links li {
    float: left;
}

#app header.navbar-default .navbar-header #block-quicklinks ul.menu--quick-links li a {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 400;
}

@media all and (max-width: 991px) {
    #app header.navbar-default .navbar-header #block-quicklinks ul.menu--quick-links li a {
        font-size: 12px;
        padding: 10px;
    }
}

#app header.navbar-default .navbar-header #block-quicklinks ul.menu--quick-links li a:hover,
#app header.navbar-default .navbar-header #block-quicklinks ul.menu--quick-links li a:active,
#app header.navbar-default .navbar-header #block-quicklinks ul.menu--quick-links li a:focus {
    background: none;
    text-decoration: underline;
}

/* End header styles */

.root .toolbar *:not(:hover) {
    background-color: transparent;
}

.section-label,
.ms-Label {
    color: #BE2F37;
    font-size: 14px;
    font-weight: 500;
}

@media print {
    .print-panel {
        padding: 12px;
        width: 90%;
    }
}

/* Start of paste styles */
html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

a {
    background-color: transparent;
}

a:active,
a:hover {
    outline: 0;
}

b,
strong {
    font-weight: bold;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

img {
    border: 0;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

a {
    color: #337ab7;
    text-decoration: none;
}

a:hover,
a:focus {
    color: #23527c;
    text-decoration: underline;
}

a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

img {
    vertical-align: middle;
}

[role=button] {
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small,
.h1 small,
.h1 .small,
.h2 small,
.h2 .small,
.h3 small,
.h3 .small,
.h4 small,
.h4 .small,
.h5 small,
.h5 .small,
.h6 small,
.h6 .small {
    font-weight: 400;
    line-height: 1;
    color: #777777;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

h1 small,
h1 .small,
.h1 small,
.h1 .small,
h2 small,
h2 .small,
.h2 small,
.h2 .small,
h3 small,
h3 .small,
.h3 small,
.h3 .small {
    font-size: 65%;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin-top: 10px;
    margin-bottom: 10px;
}

h4 small,
h4 .small,
.h4 small,
.h4 .small,
h5 small,
h5 .small,
.h5 small,
.h5 .small,
h6 small,
h6 .small,
.h6 small,
.h6 .small {
    font-size: 75%;
}

h1,
.h1 {
    font-size: 36px;
}

h2,
.h2 {
    font-size: 30px;
}

h3,
.h3 {
    font-size: 24px;
}

h4,
.h4 {
    font-size: 18px;
}

h5,
.h5 {
    font-size: 14px;
}

h6,
.h6 {
    font-size: 12px;
}

p {
    margin: 0 0 10px;
}

ul,
ol {
    margin-top: 0;
    margin-bottom: 10px;
}

ul ul,
ul ol,
ol ul,
ol ol {
    margin-bottom: 0;
}

.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.container-fluid:before,
.container-fluid:after {
    display: table;
    content: " ";
}

.container-fluid:after {
    clear: both;
}

@media (min-width: 768px) {
    .navbar-right .dropdown-menu {
        right: 0;
        left: auto;
    }

    .navbar-right .dropdown-menu-left {
        left: 0;
        right: auto;
    }
}

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav:before,
.nav:after {
    display: table;
    content: " ";
}

.nav:after {
    clear: both;
}

.nav>li {
    position: relative;
    display: block;
}

.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.nav>li>a:hover,
.nav>li>a:focus {
    text-decoration: none;
    background-color: #eeeeee;
}

.nav>li.disabled>a {
    color: #777777;
}

.nav>li.disabled>a:hover,
.nav>li.disabled>a:focus {
    color: #777777;
    text-decoration: none;
    cursor: not-allowed;
    background-color: transparent;
}

.nav .open>a,
.nav .open>a:hover,
.nav .open>a:focus {
    background-color: #eeeeee;
    border-color: #337ab7;
}

.nav>li>a>img {
    max-width: none;
}

.navbar {
    position: relative;
    min-height: 50px;
    border: 1px solid transparent;
}

.navbar:before,
.navbar:after {
    display: table;
    content: " ";
}

.navbar:after {
    clear: both;
}

@media (min-width: 768px) {
    .navbar {
        border-radius: 4px;
    }
}

.navbar-header:before,
.navbar-header:after {
    display: table;
    content: " ";
}

.navbar-header:after {
    clear: both;
}

@media (min-width: 768px) {
    .navbar-header {
        float: left;
    }
}

.navbar-fixed-top,
.navbar-fixed-bottom {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
}

.navbar-fixed-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
    max-height: 340px;
}

@media (max-device-width: 480px) and (orientation:landscape) {

    .navbar-fixed-top .navbar-collapse,
    .navbar-fixed-bottom .navbar-collapse {
        max-height: 200px;
    }
}

@media (min-width: 768px) {

    .navbar-fixed-top,
    .navbar-fixed-bottom {
        border-radius: 0;
    }
}

.navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
}

.navbar-fixed-bottom {
    bottom: 0;
    margin-bottom: 0;
    border-width: 1px 0 0;
}

.container>.navbar-header,
.container>.navbar-collapse,
.container-fluid>.navbar-header,
.container-fluid>.navbar-collapse {
    margin-right: -15px;
    margin-left: -15px;
}

@media (min-width: 768px) {

    .container>.navbar-header,
    .container>.navbar-collapse,
    .container-fluid>.navbar-header,
    .container-fluid>.navbar-collapse {
        margin-right: 0;
        margin-left: 0;
    }
}

.navbar-static-top {
    z-index: 1000;
    border-width: 0 0 1px;
}

@media (min-width: 768px) {
    .navbar-static-top {
        border-radius: 0;
    }
}

.navbar-brand {
    float: left;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none;
}

.navbar-brand>img {
    display: block;
}

@media (min-width: 768px) {

    .navbar>.container .navbar-brand,
    .navbar>.container-fluid .navbar-brand {
        margin-left: -15px;
    }
}

.navbar-nav {
    margin: 7.5px -15px;
}

.navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
}

@media (max-width: 767px) {
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none;
    }

    .navbar-nav .open .dropdown-menu>li>a,
    .navbar-nav .open .dropdown-menu .dropdown-header {
        padding: 5px 15px 5px 25px;
    }

    .navbar-nav .open .dropdown-menu>li>a {
        line-height: 20px;
    }

    .navbar-nav .open .dropdown-menu>li>a:hover,
    .navbar-nav .open .dropdown-menu>li>a:focus {
        background-image: none;
    }
}

@media (min-width: 768px) {
    .navbar-nav {
        float: left;
        margin: 0;
    }

    .navbar-nav>li {
        float: left;
    }

    .navbar-nav>li>a {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.navbar-nav>li>.dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.navbar-fixed-bottom .navbar-nav>li>.dropdown-menu {
    margin-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.navbar-btn {
    margin-top: 8px;
    margin-bottom: 8px;
}

.navbar-btn.btn-sm,
.btn-group-sm>.navbar-btn.btn {
    margin-top: 10px;
    margin-bottom: 10px;
}

.navbar-btn.btn-xs,
.btn-group-xs>.navbar-btn.btn {
    margin-top: 14px;
    margin-bottom: 14px;
}

.navbar-text {
    margin-top: 15px;
    margin-bottom: 15px;
}

@media (min-width: 768px) {
    .navbar-text {
        float: left;
        margin-right: 15px;
        margin-left: 15px;
    }
}

@media (min-width: 768px) {
    .navbar-left {
        float: left !important;
    }

    .navbar-right {
        float: right !important;
        margin-right: -15px;
    }

    .navbar-right~.navbar-right {
        margin-right: 0;
    }
}

.navbar-default {
    background-color: #f8f8f8;
    border-color: #e7e7e7;
}

.navbar-default .navbar-brand {
    color: #777;
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
    color: #5e5e5e;
    background-color: transparent;
}

.navbar-default .navbar-text {
    color: #777;
}

.navbar-default .navbar-nav>li>a {
    color: #777;
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>a:focus {
    color: #333;
    background-color: transparent;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    color: #555;
    background-color: #e7e7e7;
}

.navbar-default .navbar-nav>.disabled>a,
.navbar-default .navbar-nav>.disabled>a:hover,
.navbar-default .navbar-nav>.disabled>a:focus {
    color: #ccc;
    background-color: transparent;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:hover,
.navbar-default .navbar-nav>.open>a:focus {
    color: #555;
    background-color: #e7e7e7;
}

@media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu>li>a {
        color: #777;
    }

    .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus {
        color: #333;
        background-color: transparent;
    }

    .navbar-default .navbar-nav .open .dropdown-menu>.active>a,
    .navbar-default .navbar-nav .open .dropdown-menu>.active>a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu>.active>a:focus {
        color: #555;
        background-color: #e7e7e7;
    }

    .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a,
    .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:focus {
        color: #ccc;
        background-color: transparent;
    }
}

.navbar-default .navbar-toggle {
    border-color: #ddd;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: #ddd;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #888;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
    border-color: #e7e7e7;
}

.navbar-default .navbar-link {
    color: #777;
}

.navbar-default .navbar-link:hover {
    color: #333;
}

.navbar-default .btn-link {
    color: #777;
}

.navbar-default .btn-link:hover,
.navbar-default .btn-link:focus {
    color: #333;
}

.navbar-default .btn-link[disabled]:hover,
.navbar-default .btn-link[disabled]:focus,
fieldset[disabled] .navbar-default .btn-link:hover,
fieldset[disabled] .navbar-default .btn-link:focus {
    color: #ccc;
}

.pull-right {
    float: right !important;
}

.pull-left {
    float: left !important;
}

@-ms-viewport {
    width: device-width;
}

@media all and (min-width: 992px) {
    .display-flex {
        display: flex;
    }
}

@media all and (max-width: 991px) {
    .display-flex-mobile {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .display-flex-mobile .mobile-order-1 {
        order: 1;
    }

    .display-flex-mobile .mobile-order-2 {
        order: 2;
    }
}

.uppercase {
    text-transform: uppercase;
}

.remove-margins {
    margin-left: -30px;
    width: calc(100% + 60px);
}

.float-right {
    float: right;
}

@media all and (min-width: 768px) {
    html {
        font-size: 18px;
    }
}

@media all and (min-width: 768px) {
    body {
        font-size: 18px;
    }
}

body .container,
body .container-fluid {
    width: 100%;
}

@media all and (min-width: 768px) {

    body .container,
    body .container-fluid {
        width: 100%;
    }
}

@media all and (min-width: 992px) {

    body .container,
    body .container-fluid {
        width: 100%;
    }
}

@media all and (min-width: 1200px) {

    body .container,
    body .container-fluid {
        width: 100%;
        max-width: 1440px;
    }
}

body .container.main-container,
body .container.homepage-container,
body .container-fluid.main-container,
body .container-fluid.homepage-container {
    width: 100vw;
    padding: 0;
    margin: 0;
    max-width: none;
}

body .container.main-container>.row,
body .container.homepage-container>.row,
body .container-fluid.main-container>.row,
body .container-fluid.homepage-container>.row {
    max-width: 1440px;
    margin: 0 auto;
}

h1 {
    color: #211C50;
    font-size: 2.2222222222rem;
    line-height: 1.05;
}

@media all and (min-width: 768px) {
    h1 {
        font-size: 3.3333333333rem;
        line-height: 0.9166666667;
    }
}

@media all and (min-width: 992px) {
    h1 {
        font-size: 3.2rem;
    }
}

h2 {
    color: #004990;
    font-size: 1.5555555556rem;
    line-height: 1.25;
}

@media all and (min-width: 768px) {
    h2 {
        font-size: 2.2222222222rem;
        line-height: 1.1428571429;
    }
}

h3 {
    color: #BE2F37;
    font-size: 1.2222222222rem;
    line-height: 1.1363636364;
}

@media all and (min-width: 768px) {
    h3 {
        font-size: 1.4444444444rem;
        line-height: 1.3846153846;
    }
}

@media all and (min-width: 768px) {
    h3 {
        font-size: 1.6666666667rem;
    }
}

h4 {
    color: #004990;
    font-size: 24px;
}

@media all and (min-width: 768px) {
    h4 {
        font-size: 24px;
    }
}

h5 {
    color: #211C50;
    font-size: 22px;
}

@media all and (min-width: 768px) {
    h5 {
        font-size: 22px;
    }
}

h6 {
    color: #333232;
    font-size: 20px;
}

.header-section {
    background: #004990;
    padding: 150px 2rem 3rem 2rem;
    position: relative;
}

.header-section:after {
    content: "";
    background-image: linear-gradient(to left top, #FFF 0%, #FFF 50%, transparent 50%);
    position: absolute;
    width: 100vw;
    height: 2rem;
    bottom: 0;
    left: 0;
}

@media all and (min-width: 768px) {
    .header-section {
        position: relative;
        min-height: auto;
        padding: 65px 4rem 7rem 4rem;
    }

    .header-section:after {
        content: "";
        background-image: linear-gradient(to left top, #FFF 0%, #FFF 50%, transparent 50%);
        position: absolute;
        width: 100%;
        height: 4.5rem;
        bottom: 0;
        left: 0;
    }
}

@media all and (min-width: 992px) {
    .header-section {
        position: relative;
    }

    .header-section:after {
        content: "";
        background-image: linear-gradient(to left top, #FFF 0%, #FFF 50%, transparent 50%);
        position: absolute;
        width: 100%;
        height: 7.65rem;
        bottom: 0;
        left: 0;
    }
}

.header-section h1 {
    color: #FFF;
    border: none;
}

footer.footer.container-fluid {
    padding: 0;
    margin: 0;
    max-width: none;
    border: none;
    margin-top: 45px;
}

footer.footer.container-fluid .region-footer {
    background: #211C50;
    color: #FFF;
    max-width: 1440px;
    margin: 0 auto;
    font-size: 14px;
    display: block;
    height: 100%;
    min-height: 450px;
    position: relative;
    padding: 30px 15px;
}

@media all and (min-width: 768px) {
    footer.footer.container-fluid .region-footer {
        min-height: 530px;
    }
}

@media all and (min-width: 992px) {
    footer.footer.container-fluid .region-footer {
        min-height: 300px;
    }
}

footer.footer.container-fluid ul.nav li a {
    color: #FFF;
    padding: 5px 15px;
}

footer.footer.container-fluid ul.nav li a:hover,
footer.footer.container-fluid ul.nav li a:active,
footer.footer.container-fluid ul.nav li a:focus {
    background-color: transparent;
    text-decoration: underline;
}

@media all and (max-width: 768px) {
    footer.footer.container-fluid ul.nav.menu--footer li a {
        padding: 5px 0;
    }
}

footer.footer.container-fluid ul.nav.menu--footer-social li a {
    padding: 5px 15px;
}

footer.footer.container-fluid ul.nav.menu--footer-social li a:before {
    content: "";
    position: absolute;
    padding-left: 15px;
    width: 17px;
    height: 17px;
    background-repeat: no-repeat;
    background-size: contain;
    left: -15px;
    top: 7px;
}

/* footer.footer.container-fluid ul.nav.menu--footer-social li a.facebook:before {
    background-image: url(/themes/custom/ata/images/facebook.svg);
}

footer.footer.container-fluid ul.nav.menu--footer-social li a.twitter:before {
    background-image: url(/themes/custom/ata/images/twitter.svg);
    left: -19px;
}

footer.footer.container-fluid ul.nav.menu--footer-social li a.linkedin:before {
    background-image: url(/themes/custom/ata/images/linkedin.svg);
    left: -17px;
}

footer.footer.container-fluid ul.nav.menu--footer-social li a.youtube:before {
    background-image: url(/themes/custom/ata/images/youtube.svg);
    left: -18px;
} */

footer.footer.container-fluid .footer-menus .footer-menu {
    margin-bottom: 2rem;
    padding-left: 0;
}

@media all and (min-width: 768px) {
    footer.footer.container-fluid .footer-menus {
        margin-bottom: 4rem;
        padding-left: 0;
    }
}

@media all and (min-width: 992px) {
    footer.footer.container-fluid .footer-menus {
        margin-bottom: 0;
        padding-left: 15px;
    }
}

footer.footer.container-fluid .footer-branding {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 110px;
    align-items: flex-start;
}

@media all and (max-width: 768px) {
    footer.footer.container-fluid .footer-addresses {
        padding: 25px 15px 50px;
    }
}

@media all and (min-width: 992px) {
    footer.footer.container-fluid .footer-addresses {
        padding-top: 0;
    }
}

footer.footer.container-fluid .footer-addresses .address {
    padding: 10px 15px;
}

footer.footer.container-fluid .footer-addresses .block-block-content {
    margin-top: 5px;
}

footer.footer.container-fluid .copyright {
    font-size: 11px;
    position: absolute;
    bottom: 15px;
    left: 1rem;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {

    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12 {
        float: left;
    }

    .col-sm-1 {
        width: 8.3333333333%;
    }

    .col-sm-2 {
        width: 16.6666666667%;
    }

    .col-sm-3 {
        width: 25%;
    }

    .col-sm-4 {
        width: 33.3333333333%;
    }

    .col-sm-5 {
        width: 41.6666666667%;
    }

    .col-sm-6 {
        width: 50%;
    }

    .col-sm-7 {
        width: 58.3333333333%;
    }

    .col-sm-8 {
        width: 66.6666666667%;
    }

    .col-sm-9 {
        width: 75%;
    }

    .col-sm-10 {
        width: 83.3333333333%;
    }

    .col-sm-11 {
        width: 91.6666666667%;
    }

    .col-sm-12 {
        width: 100%;
    }

    .col-sm-pull-0 {
        right: auto;
    }

    .col-sm-pull-1 {
        right: 8.3333333333%;
    }

    .col-sm-pull-2 {
        right: 16.6666666667%;
    }

    .col-sm-pull-3 {
        right: 25%;
    }

    .col-sm-pull-4 {
        right: 33.3333333333%;
    }

    .col-sm-pull-5 {
        right: 41.6666666667%;
    }

    .col-sm-pull-6 {
        right: 50%;
    }

    .col-sm-pull-7 {
        right: 58.3333333333%;
    }

    .col-sm-pull-8 {
        right: 66.6666666667%;
    }

    .col-sm-pull-9 {
        right: 75%;
    }

    .col-sm-pull-10 {
        right: 83.3333333333%;
    }

    .col-sm-pull-11 {
        right: 91.6666666667%;
    }

    .col-sm-pull-12 {
        right: 100%;
    }

    .col-sm-push-0 {
        left: auto;
    }

    .col-sm-push-1 {
        left: 8.3333333333%;
    }

    .col-sm-push-2 {
        left: 16.6666666667%;
    }

    .col-sm-push-3 {
        left: 25%;
    }

    .col-sm-push-4 {
        left: 33.3333333333%;
    }

    .col-sm-push-5 {
        left: 41.6666666667%;
    }

    .col-sm-push-6 {
        left: 50%;
    }

    .col-sm-push-7 {
        left: 58.3333333333%;
    }

    .col-sm-push-8 {
        left: 66.6666666667%;
    }

    .col-sm-push-9 {
        left: 75%;
    }

    .col-sm-push-10 {
        left: 83.3333333333%;
    }

    .col-sm-push-11 {
        left: 91.6666666667%;
    }

    .col-sm-push-12 {
        left: 100%;
    }

    .col-sm-offset-0 {
        margin-left: 0%;
    }

    .col-sm-offset-1 {
        margin-left: 8.3333333333%;
    }

    .col-sm-offset-2 {
        margin-left: 16.6666666667%;
    }

    .col-sm-offset-3 {
        margin-left: 25%;
    }

    .col-sm-offset-4 {
        margin-left: 33.3333333333%;
    }

    .col-sm-offset-5 {
        margin-left: 41.6666666667%;
    }

    .col-sm-offset-6 {
        margin-left: 50%;
    }

    .col-sm-offset-7 {
        margin-left: 58.3333333333%;
    }

    .col-sm-offset-8 {
        margin-left: 66.6666666667%;
    }

    .col-sm-offset-9 {
        margin-left: 75%;
    }

    .col-sm-offset-10 {
        margin-left: 83.3333333333%;
    }

    .col-sm-offset-11 {
        margin-left: 91.6666666667%;
    }

    .col-sm-offset-12 {
        margin-left: 100%;
    }
}

@media (min-width: 992px) {

    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
        float: left;
    }


    .col-md-1 {
        width: 8.3333333333%;
    }

    .col-md-2 {
        width: 16.6666666667%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-4 {
        width: 33.3333333333%;
    }

    .col-md-5 {
        width: 41.6666666667%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-7 {
        width: 58.3333333333%;
    }

    .col-md-8 {
        width: 66.6666666667%;
    }

    .col-md-9 {
        width: 75%;
    }

    .col-md-10 {
        width: 83.3333333333%;
    }

    .col-md-11 {
        width: 91.6666666667%;
    }

    .col-md-12 {
        width: 100%;
    }

    .col-md-pull-0 {
        right: auto;
    }

    .col-md-pull-1 {
        right: 8.3333333333%;
    }

    .col-md-pull-2 {
        right: 16.6666666667%;
    }

    .col-md-pull-3 {
        right: 25%;
    }

    .col-md-pull-4 {
        right: 33.3333333333%;
    }

    .col-md-pull-5 {
        right: 41.6666666667%;
    }

    .col-md-pull-6 {
        right: 50%;
    }

    .col-md-pull-7 {
        right: 58.3333333333%;
    }

    .col-md-pull-8 {
        right: 66.6666666667%;
    }

    .col-md-pull-9 {
        right: 75%;
    }

    .col-md-pull-10 {
        right: 83.3333333333%;
    }

    .col-md-pull-11 {
        right: 91.6666666667%;
    }

    .col-md-pull-12 {
        right: 100%;
    }

    .col-md-push-0 {
        left: auto;
    }

    .col-md-push-1 {
        left: 8.3333333333%;
    }

    .col-md-push-2 {
        left: 16.6666666667%;
    }

    .col-md-push-3 {
        left: 25%;
    }

    .col-md-push-4 {
        left: 33.3333333333%;
    }

    .col-md-push-5 {
        left: 41.6666666667%;
    }

    .col-md-push-6 {
        left: 50%;
    }

    .col-md-push-7 {
        left: 58.3333333333%;
    }

    .col-md-push-8 {
        left: 66.6666666667%;
    }

    .col-md-push-9 {
        left: 75%;
    }

    .col-md-push-10 {
        left: 83.3333333333%;
    }

    .col-md-push-11 {
        left: 91.6666666667%;
    }

    .col-md-push-12 {
        left: 100%;
    }

    .col-md-offset-0 {
        margin-left: 0%;
    }

    .col-md-offset-1 {
        margin-left: 8.3333333333%;
    }

    .col-md-offset-2 {
        margin-left: 16.6666666667%;
    }

    .col-md-offset-3 {
        margin-left: 25%;
    }

    .col-md-offset-4 {
        margin-left: 33.3333333333%;
    }

    .col-md-offset-5 {
        margin-left: 41.6666666667%;
    }

    .col-md-offset-6 {
        margin-left: 50%;
    }

    .col-md-offset-7 {
        margin-left: 58.3333333333%;
    }

    .col-md-offset-8 {
        margin-left: 66.6666666667%;
    }

    .col-md-offset-9 {
        margin-left: 75%;
    }

    .col-md-offset-10 {
        margin-left: 83.3333333333%;
    }

    .col-md-offset-11 {
        margin-left: 91.6666666667%;
    }

    .col-md-offset-12 {
        margin-left: 100%;
    }
}